/* Ensure no white space around the entire body */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    background-color: #0f0f1c; /* Dark navy background */
    font-family: "Rubik", sans-serif;
}

/* Hero Section */
.hero-section {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    padding: 5vh 10vw;
    background-color: #0f0f1c; /* Matches the overall background */
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

/* Text Section */
.hero-section h1 {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    color: #ffffff;
    margin: 0 0 1rem;
    line-height: 1.2;
}

.hero-section h2 {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: #7c7c9a; /* Light gray text */
    margin: 0 0 1rem;
}

.hero-section h5 {
    font-weight: 300;
    color: #ffffff;
    font-size: 1.25rem;
    margin: 0 0 1rem;
}

/* Subtext */
.hero-section p {
    color: #a3a3c2;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

/* Button */
.learn-more-button {
    background-color: #ffffff;
    color: #0f0f1c;
    border: none;
    padding: 12px 24px;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out;
}

.learn-more-button:hover {
    background-color: #eaeaea; /* Slightly lighter hover effect */
    transform: scale(1.05); /* Slight zoom effect */
}

.material-symbols-outlined {
    font-size: 1.2rem;
}

/* Image Section */
.Img1 {
    position: absolute;
    width: 550px; /* Maintains original size */
    height: 400px;
    left: 520px; /* Maintains original position */
    top: calc(50% - 400px / 2);
    transform: translateX(40vw); /* Keeps original alignment */
    border-radius: 40px 0px 0px 40px;
    z-index: 2;
}

.Img2 {
    position: absolute;
    width: 380px; /* Maintains original size */
    height: 250px;
    left: 500px; /* Maintains original position */
    top: calc(50% - 250px / 2);
    transform: translateX(37vw); /* Keeps original alignment */
    border-radius: 40px 0px 0px 40px;
    z-index: 1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .hero-section {
        flex-direction: column;
        text-align: center;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 1.25rem;
    }

    h5 {
        font-size: 1rem;
    }

    .Img1,
    .Img2 {
        position: static;
        margin: 1rem auto;
        width: 80%;
        height: auto;
        transform: none;
    }
}
